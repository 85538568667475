// 成功提示的文案
export const successMessage = [
  {
    title: '您的资质认证申请提交成功！',
    text: '我们将尽快为您审核'
  },
  {
    title: '您的资质认证申请审核中...',
    text: '我们将尽快为您审核'
  },
  {
    title: '您的资质认证申请审核失败',
    text: '可重新提交资料'
  }
]

// 订单支付结果
export const orderPayList = {
  statusList: [
    {
      class: 'bg_top_linear-gradient bg_top_linear-blue',
      img: 'https://oss.xiaoyi120.com/wholesale/pay_success.png',
      typeImg: 'https://oss.xiaoyi120.com/wholesale/pay_type_weixin.png',
      name: '支付成功', msg: '感谢您的购买', typeName: '微信支付', color: '#0767AB', btn1: '查看订单', btn2: '返回首页'
    },
    {
      class: 'bg_top_linear-gradient bg_top_linear-orange',
      img: 'https://oss.xiaoyi120.com/wholesale/pay_failed.png',
      typeImg: 'https://oss.xiaoyi120.com/wholesale/pay_type_weixin.png',
      name: '支付失败', msg: '请尝试重新支付', typeName: '微信支付', color: '#D1531C', btn1: '重新支付', btn2: '返回首页'
    }
  ]
}
// 云课堂首页 推荐课程
export const classProject = ['1522090644850475008', '1518486312422866944', '1518776716225544192', '1518782658161803264']
// 个人中心 订单卡片
export const userOrderCard = [
  { name: '待付款', span: '5', id: '1', img: 'https://oss.xiaoyi120.com/shop2.0/my/order_card1.png' },
  { name: '待发/预售', span: '5', id: '4', img: 'https://oss.xiaoyi120.com/shop2.0/my/order_card2.png' },
  { name: '待收货', span: '4', id: '5', img: 'https://oss.xiaoyi120.com/shop2.0/my/order_card3.png' },
  { name: '已完成', span: '5', id: '2', img: 'https://oss.xiaoyi120.com/shop2.0/my/order_card4.png' },
  { name: '退款/售后', span: '5', id: '6', img: 'https://oss.xiaoyi120.com/shop2.0/my/order_card5.png' }
]
// 个人中心 我的账户
export const userPacket = [
  // { name: '我的白条', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user01.png' },
  // { name: '优惠券', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user02.png' },
  { name: '收货地址', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user03.png' },
  { name: '我的收藏', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user04.png' },
  // { name: '经销商', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user05.png' },
  { name: '客服', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user06.png' }
  // { name: '公告', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user07.png' },
  // { name: '邀请有礼', span: '6', id: '', img: 'https://oss.xiaoyi120.com/shop2.0/my/user08.png' }
]
// 物流信息
export const wuliuList = [
  { name: '顺丰', id: 'SF' },
  { name: '百世', id: 'HTKY' },
  { name: '中通', id: 'ZTO' },
  { name: '申通', id: 'STO' },
  { name: '圆通', id: 'YTO' },
  { name: '韵达', id: 'YD' },
  { name: '邮政平邮', id: 'YZPY' },
  { name: 'EMS', id: 'EMS' },
  { name: '天天', id: 'HHTT' },
  { name: '京东物流', id: 'JD' },
  { name: '德邦物流', id: 'DBL' },
  { name: '宅急送', id: 'ZJS' },
  { name: '优速快递', id: 'UC' }
]

// vip svip 权益
export const vipAuth1 = [
  { title: '特价福利', msg: '实物领取', icon: '1' },
  { title: '专属客服', msg: '专享线上客服', icon: '2' },
  { title: '包邮政策', msg: '满额包邮', icon: '3' }
]
export const vipAuth = [
  { title: '特价福利', msg: '实物领取', icon: '1' },
  { title: '包邮政策', msg: '满额包邮', icon: '2' },
  { title: '专属客服', msg: '专享线上客服', icon: '3' },
  { title: '支付享', msg: '与优惠活动同享', icon: '4' }
]
export const svipAuth = [
  { title: '特价福利', msg: '实物领取', icon: '1' },
  { title: '专属客服', msg: '专享线上客服', icon: '3' },
  { title: '支付享', msg: '与优惠活动同享', icon: '4' },
  { title: '铺货寄卖', msg: '先用后结', icon: '5' }
]
export const vip15Auth = [
  { title: '专属客服', msg: '专享线上客服', msg2: '线上/线下客服', icon: '1' },
  { title: '特价福利', msg: '实物领取', icon: '2' },
  { title: '包邮政策', msg: '满额包邮', icon: '3' },
  { title: '支付享', msg: '与优惠活动同享', icon: '4' }
]
export const svip15Auth = [
  { title: '专属客服', msg: '线上/线下客服', icon: '1' },
  { title: '特价福利', msg: '实物领取', icon: '2' },
  { title: '包邮政策', msg: '满额包邮', icon: '3' },
  { title: '支付享', msg: '与优惠活动同享', icon: '4' },
  { title: '铺货寄卖', msg: '先用后结', icon: '5' }
]
// 贝思倍健 一级栏目
export const bsbj_level01 = [
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card21.png', name: '仪器设备', icon: '21', id: '1810869873648209920' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card20.png', name: '神经用药', icon: '20', id: '1802509811489837056' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card19.png', name: '皮肤用药', icon: '19', id: '1800781367106211840' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card18.png', name: '泌尿用药', icon: '18', id: '1795378364488486912' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card17.png', name: '注射用药', icon: '17', id: '1793518294846803968' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card14.png', name: '消化系统', icon: '14', id: '1787306041638260736' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card15.png', name: '消炎止痛', icon: '15', id: '1787306001914007552' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card16.png', name: '呼吸系统', icon: '16', id: '1786946227905105920' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card13.png', name: '五官用药', icon: '13', id: '1779350130915414016' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card12.png', name: '驱虫用药', icon: '12', id: '1765185399182987264' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card01.png', name: '外科手术', icon: '1', id: '1744269902711230464' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card02.png', name: '输液注射', icon: '2', id: '1744271605338607616' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card03.png', name: '泌尿', icon: '3', id: '1744278764008902656' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card04.png', name: '呼吸麻醉', icon: '4', id: '1744278905524719616' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card05.png', name: '辅助喂药 ', icon: '5', id: '1744279077252108288' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card06.png', name: '其他耗材', icon: '6', id: '1744279179471491072' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card07.png', name: '手术器械', icon: '7', id: '1744279329048760320' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card08.png', name: '肝脏用药', icon: '8', id: '1744279541175685120' },
  // { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card09.png', name: '关节护理', icon: '9', id: '1744279606430666752' },
  // { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card10.png', name: '口腔护理', icon: '10', id: '1744279765478674432' },
  { img: 'https://oss.xiaoyi120.com/shop2.0/subjectPage/bsbj/bsbj_card11.png', name: '恢复与免疫', icon: '11', id: '1744279864174841856' }
]

export default {
  successMessage: successMessage,
  orderPayList: orderPayList,
  classProject: classProject,
  userOrderCard: userOrderCard,
  wuliuList: wuliuList,
  vipAuth1: vipAuth1,
  vipAuth: vipAuth,
  svipAuth: svipAuth,
  vip15Auth: vip15Auth,
  svip15Auth: svip15Auth,
  bsbj_level01: bsbj_level01
}
